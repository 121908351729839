/** @format */

import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";

const Social = () => {
  return (
    <div className="home__social">
      <a href="https://www.facebook.com/kushal.shrestha.1272" className="home__social-icon">
        <FaFacebook />
      </a>
      <a href="https://www.instagram.com/not.so.kushal/" className="home__social-icon">
        <FaInstagram />
      </a>
      <a href="https://x.com/not_so_kushal" className="home__social-icon">
        <FaTwitter />
      </a>
      <a href="https://github.com/notso-kushal" className="home__social-icon">
        <FaGithub />
      </a>
      <a href="https://www.linkedin.com/in/kushal-shrestha-a35915296/" className="home__social-icon">
        <FaLinkedin />
      </a>
    </div>
  );
};

export default Social;
