/** @format */
import React, { useState } from "react";
import "./header.css";
import {
  HiOutlineHome,
  HiOutlineUser,
  HiOutlineBadgeCheck,
  HiOutlineClipboardList,
  HiOutlinePhotograph,
  HiOutlineMail,
  HiX,
  HiOutlineMenu,
} from "react-icons/hi";

const Header = () => {
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-header");
  });

  const [Toggle, showMenu] = useState(false);

  // NEW: Function to close the menu when an option is selected
  const handleMenuClose = () => showMenu(false);

  return (
    <header className="header">
      <nav className="nav container">
        {/* <a href="index.html" className="nav__logo">
          Portfolio
        </a> */}
        <div className={`nav__menu ${Toggle ? "show-menu" : ""}`}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a href="#home" className="nav__link" onClick={handleMenuClose}>
                {" "}
                {/* NEW onClick */}
                <HiOutlineHome className="nav__icon" />
                Home
              </a>
            </li>
            <li className="nav__item">
              <a href="#about" className="nav__link" onClick={handleMenuClose}>
                {" "}
                {/* NEW onClick */}
                <HiOutlineUser className="nav__icon" />
                About
              </a>
            </li>
            <li className="nav__item">
              <a href="#skills" className="nav__link" onClick={handleMenuClose}>
                {" "}
                {/* NEW onClick */}
                <HiOutlineBadgeCheck className="nav__icon" />
                Skills
              </a>
            </li>
            <li className="nav__item">
              <a href="#services" className="nav__link" onClick={handleMenuClose}>
                {" "}
                {/* NEW onClick */}
                <HiOutlineClipboardList className="nav__icon" />
                Services
              </a>
            </li>
            <li className="nav__item">
              <a href="#portfolio" className="nav__link" onClick={handleMenuClose}>
                {" "}
                {/* NEW onClick */}
                <HiOutlinePhotograph className="nav__icon" />
                Projects
              </a>
            </li>
            <li className="nav__item">
              <a href="#contact" className="nav__link" onClick={handleMenuClose}>
                {" "}
                {/* NEW onClick */}
                <HiOutlineMail className="nav__icon" />
                Contact
              </a>
            </li>
          </ul>
          <HiX className="nav__close" onClick={() => showMenu(!Toggle)} />
        </div>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <HiOutlineMenu />
        </div>
      </nav>
    </header>
  );
};

export default Header;
