/** @format */

import React from "react";
import "./footer.css";
import { FaFacebook, FaInstagram, FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Kushal Shrestha</h1>
        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>
          <li>
            <a href="#portfolio" className="footer__link">
              Projects
            </a>
          </li>
          <li>
            <a href="#testimonials" className="footer__link">
              Testimonials
            </a>
          </li>
        </ul>
        <div className="footer__social">
          <a
            href="https://www.facebook.com/kushal.shrestha.1272"
            className="home__social-icon"
            target="_blank"
            rel="noreferrer">
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/not.so.kushal/"
            className="home__social-icon"
            target="_blank"
            rel="noreferrer">
            <FaInstagram />
          </a>
          <a href="https://x.com/not_so_kushal" className="home__social-icon" target="_blank" rel="noreferrer">
            <FaTwitter />
          </a>
          <a href="https://github.com/notso-kushal" className="home__social-icon" target="_blank" rel="noreferrer">
            <FaGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/kushal-shrestha-a35915296/"
            className="home__social-icon"
            target="_blank"
            rel="noreferrer">
            <FaLinkedin />
          </a>
        </div>
        <span className="footer__copy"></span>
      </div>
    </footer>
  );
};

export default Footer;
