/** @format */

import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";

export default function Particle() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {};

  return (
    <>
      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            zIndex: -1, // Keep particles below other components
            pointerEvents: "none", // Prevent interaction with particles
          }}
          options={{
            fpsLimit: 120,
            interactivity: {
              detectsOn: "window",
              events: {
                onHover: {
                  enable: true,
                  mode: "bubble",
                },
                resize: { enable: true },
              },
              modes: {
                bubble: {
                  distance: 40,
                  duration: 2,
                  opacity: 8,
                  size: 6,
                },
                connect: {
                  distance: 80,
                  links: { opacity: 0.5 },
                },
                grab: { distance: 400 },
              },
            },
            particles: {
              color: { value: "#000000" },
              links: {
                color: "#000000",
                distance: 30,
                enable: true,
                opacity: 0.4,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: { default: "bounce" },
                speed: 1,
              },
              number: {
                value: 200, // Lowered particles
              },
              opacity: {
                value: { min: 0.05, max: 0.4 },
                animation: { enable: true, speed: 2 },
              },
              shape: { type: "circle" },
              size: { value: 1 },
            },
            detectRetina: true,
            polygon: {
              draw: {
                enable: true,
                stroke: {
                  color: "#fff",
                  width: 1,
                  opacity: 0.2,
                },
              },
              enable: true,
              inline: { arrangement: "equidistant" },
              move: { radius: 10 },
              scale: 0.5,
              type: "inline",
              url: "https://particles.js.org/images/smalldeer.svg",
              position: { x: 50, y: 50 },
            },
            responsive: [
              {
                maxWidth: 768,
                options: {
                  particles: {
                    number: {
                      value: 80, // Less particles on mobile
                    },
                  },
                },
              },
            ],
          }}
        />
      )}
    </>
  );
}
