/** @format */

import React from "react";
import "./Loading.css";
import "./svgloader.css";

const Loading = () => {
  return (
    <div className="loading-content">
      <svg className="loading-icon" viewBox="0 0 50 31.25" width="50">
        <path
          className="track"
          strokeWidth="4"
          fill="none"
          pathLength="100"
          d="M0.625 21.5 h10.25 l3.75 -5.875 l7.375 15 l9.75 -30 l7.375 20.875 v0 h10.25"
        />
        <path
          className="car"
          strokeWidth="4"
          fill="none"
          pathLength="100"
          d="M0.625 21.5 h10.25 l3.75 -5.875 l7.375 15 l9.75 -30 l7.375 20.875 v0 h10.25"
        />
      </svg>
      <p className="ldes">Getting everything ready for you.</p>
    </div>
  );
};

export default Loading;
