/** @format */

import React, { useEffect } from "react";
import "./scrollup.css";
import { HiOutlineArrowSmUp } from "react-icons/hi";

const ScrollUp = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollUp = document.querySelector(".scrollup");
      if (window.scrollY >= 560) {
        scrollUp.classList.add("show-scroll");
      } else {
        scrollUp.classList.remove("show-scroll");
      }
    };

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures it only runs once after the component mounts

  const handleScrollUpClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button onClick={handleScrollUpClick} className="scrollup" aria-label="Scroll to top">
      <HiOutlineArrowSmUp className="scrollup__icon" />
    </button>
  );
};

export default ScrollUp;
