/** @format */

import React from "react";
import { HiOutlineArrowSmDown } from "react-icons/hi";
import { Player } from "@lottiefiles/react-lottie-player";
import scrollDownAnimation from "../../assets/scrollDown.json";

const Scroll = () => {
  return (
    <div className="home__scroll">
      <a href="#about" className="home__scroll-button button--flex">
        <Player
          autoplay
          loop
          src={scrollDownAnimation}
          style={{ height: "30px", width: "30px", marginRight: "10px" }}
        />
        <span className="home__scroll-name">Go down on me</span>
        <HiOutlineArrowSmDown className="home__scroll-arrow" />
      </a>
    </div>
  );
};

export default Scroll;
