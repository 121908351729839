/** @format */

import Image1 from "../../assets/ironman.png";
import Image2 from "../../assets/bpanther.png";
import Image3 from "../../assets/apple.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Iron Man",
    description: "Kushal's the one who created Jarvis.",
  },
  {
    id: 2,
    image: Image2,
    title: "Black Panther",
    description: "He paved the road to Wakands's success.",
  },
  {
    id: 3,
    image: Image3,
    title: "Apple inc.",
    description: "iPhone 16 was his idea.",
  },
];
