/** @format */

import Project1 from "../../assets/project1.png";
import Project2 from "../../assets/project2.png";
import Project3 from "../../assets/project3.png";
import Project4 from "../../assets/project4.png";
import Project5 from "../../assets/project5.png";
import Project6 from "../../assets/project6.png";
import Project7 from "../../assets/project7.png";

export const projectsData = [
  {
    id: 1,
    image: Project1,
    title: "TO-DO Application",
    subtitle: "Using Flutter",
    category: "Softwares",
  },
  {
    id: 2,
    image: Project2,
    title: "Calculator Application",
    subtitle: "Using Flutter",
    category: "Softwares",
  },
  {
    id: 3,
    image: Project3,
    title: "Weather Application",
    subtitle: "Using Flutter",
    category: "Softwares",
  },
  {
    id: 4,
    image: Project4,
    title: "Khaidim - Food Delievery Application",
    subtitle: "Using Flutter",
    category: "Softwares",
  },
  {
    id: 5,
    image: Project5,
    title: "Padantey - Book e-commerce website design",
    subtitle: "Using Figma",
    category: "Designs",
  },
  {
    id: 6,
    image: Project6,
    title: "ढाका - Dhaka products e-commerce website design",
    subtitle: "Using Figma",
    category: "Designs",
  },
  {
    id: 7,
    image: Project7,
    title: "संवाद - Multilingual chat application",
    subtitle: "Using Flutter",
    category: "Softwares",
  },
];

export const projectsNav = [
  {
    name: "All",
  },
  {
    name: "Softwares",
  },
  {
    name: "Designs",
  },
];
